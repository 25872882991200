<script lang="ts">
    import { Icon } from "svelte-awesome"
    import { chevron_right } from "@timephy/tui-icons-svelte"
    import Button from "./Button.svelte"
    import type { IconType } from "svelte-awesome/components/Icon.svelte"

    let className = ""
    export { className as class }

    export let icon: IconType | null = null

    export let disabled = false
</script>

<Button
    color="gray"
    class="min-w-[0] max-w-full justify-between !gap-[14px] {className}"
    {disabled}
    on:click
>
    {#if icon}
        <Icon data={icon} class="h-4 w-4 shrink-0 text-step-500" />
    {/if}
    <slot />
    <div class="grow"></div>
    <Icon data={chevron_right} class="h-3 w-3 shrink-0 text-step-500" />
</Button>
